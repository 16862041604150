import React, { ElementRef, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import "./guideline-compliance.scss";
import { FailureIcon, SuccessIcon } from "../../assets/icons";
import { Button, Checkbox } from "antd";
import {
  DislikeOutlined,
  DownCircleOutlined,
  LikeOutlined,
  UpCircleOutlined,
} from "@ant-design/icons";
import {
  AdjudicationSummaryItem,
  GuidelineSource,
  patientSummaryService,
  priorAuthService,
  uploadFileFolderService,
} from "../../api";
import { Loading } from "../../components";
import { DiagnosisProps, ProcedureProps } from "../patient-summary";
import { ReactTyped } from "react-typed";
import {
  MODAL_TYPE,
  CHECKBOX_DATA,
  GUIDELINES_CHECKBOXES,
  openNotificationWithIcon,
} from "../../utils";
import AskAuto from "../../components/ask-auto/AskAuto";
import { guidelineCompliance, hardCodedPBMDocuments } from "../case-details-container/constants";
import { SourcePopup } from "../modals/source-popup/SourcePopup";
import { useDispatch, useSelector } from "react-redux";
import {
  openModal,
  RootState,
  setCurrentPage as setGlobalCurrentPage,
  setFeedbackType,
  setGuidelinesSummary,
  setSummaryId,
  setSummaryType,
  setCurrentChunkUUID,
} from "../../store";
import Markdown from "react-markdown";

type Props = {
  enableNextTab: (selectedCheckboxes: string[]) => void;
  procedureCode?: ProcedureProps;
  diagnosisCode?: DiagnosisProps;
  projectId: number;
  documentUUID: string;
  fileName: string;
};

type IOffset = {
  begin: number;
  end: number;
};

export type IPages = {
  pageNo: number;
  offsets: IOffset[];
  chunkUUID: string;
};

export type ISources = {
  category: string;
  pages: IPages[];
  documentUUID: string;
  guidelines?: boolean;
};

const UUID_REGEX = /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/g;

export const GuidelineCompliance = ({
  enableNextTab,
  procedureCode,
  diagnosisCode,
  projectId,
  documentUUID,
  fileName,
}: Props) => {
  const [chatSource, setChatSource] = useState({} as ISources);
  const [fileUrl, setFileUrl] = useState("");
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isComplianceCollapsed, setIsComplianceCollapsed] = useState(false);
  const [showExpandCollapse, setShowExpandCollapse] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([]);
  const [guidelineComplianceData, setGuidelineComplianceData] = useState(
    [] as AdjudicationSummaryItem[]
  );
  const [showNewCompliance, setShowNewCompliance] = useState(false);
  const [newComplianceData, setNewComplianceData] = useState([] as AdjudicationSummaryItem[]);
  const [clinicalGuidelineSources, setClinicalGuidelineSources] = useState([] as GuidelineSource[]);
  const [newPatientSummaryData, setNewPatientSummaryData] = useState("");
  const [newPatientSummarySources, setNewPatientSummarySources] = useState([] as GuidelineSource[]);
  const [isFaxSummaryCollapsed, setIsFaxSummaryCollapsed] = useState(false);
  const [claimsHistory, setClaimsHistory] = useState("");
  const [isSummaryLoading, setIsSummaryLoading] = useState(false);
  const checkboxesRef = useRef<ElementRef<"div">>(null);
  const rerunRef = useRef<ElementRef<"div">>(null);
  const { projectDetails } = useSelector((state: RootState) => state.document);
  const [isCompliantDoc, setIsCompliantDoc] = useState(false);
  const dispatch = useDispatch();
  const ClinicalGuidelineSources:any = {
    "Enrolee Info.pdf":[{
      category: "Criteria.pdf",
      documentUUID: "72cba75a-edcd-4ef3-80e2-bd1c29dd7a79",
      pages: [{ pageNo: 2, offsets: [{ begin: 977, end: 1991 }], chunkUUID: "123" }],
    }],
    "case1.pdf":[{
       category:"antidiabetics-GLP-1-agonists.pdf",
       documentUUID: "9ff138b4-26c3-499f-9371-3175664a32da",
       pages: [{ pageNo: 1, offsets: [{ begin: 2274, end: 2584 }], chunkUUID: "123" },{ pageNo: 2, offsets: [{ begin: 39, end: 518 }], chunkUUID: "434" }],
    }],
    "case4.pdf":[{
      category:"antidiabetics-GLP-1-agonists.pdf",
      documentUUID: "9ff138b4-26c3-499f-9371-3175664a32da",
      pages: [{ pageNo: 1, offsets: [{ begin: 2274, end: 2584 }], chunkUUID: "123" },{ pageNo: 2, offsets: [{ begin: 39, end: 518 }], chunkUUID: "434" }],
   }],
     "case3.pdf":[{
      category:"antidiabetics-GLP-1-agonists.pdf",
      documentUUID: "9ff138b4-26c3-499f-9371-3175664a32da",
      pages: [{ pageNo: 1, offsets: [{ begin: 2274, end: 2584 }], chunkUUID: "123" },{ pageNo: 2, offsets: [{ begin: 39, end: 518 }], chunkUUID: "434" }],
   }],
   "case2.pdf":[{
    category:"antidiabetics-GLP-1-agonists.pdf",
    documentUUID: "9ff138b4-26c3-499f-9371-3175664a32da",
    pages: [{ pageNo: 1, offsets: [{ begin: 2274, end: 2584 }], chunkUUID: "123" },{ pageNo: 2, offsets: [{ begin: 39, end: 518 }], chunkUUID: "434" }],
 }],
  };

  function consolidateGuidelineSources(sources: any) {
    const consolidated = new Map();
    sources.forEach((source: any) => {
        const key = `${source.documentUUID}-${source.category}`;    
        if (consolidated.has(key)) {
            const existingEntry = consolidated.get(key);       
            source.pages.forEach((newPage: any) => {
                const pageExists = existingEntry.pages.some((existingPage: any) => {
                    if (existingPage.pageNo === newPage.pageNo) {
                        const offsetsMatch = JSON.stringify(existingPage.offsets) === JSON.stringify(newPage.offsets);
                        return offsetsMatch;
                    }
                    return false;
                });
                
                if (!pageExists) {
                    existingEntry.pages.push(newPage);
                }
            });      
            existingEntry.pages.sort((a: any, b: any) => a.pageNo - b.pageNo);           
        } else {
            const uniquePages = source.pages.reduce((acc: any[], currentPage: any) => {
                const duplicatePage = acc.find((page: any) => 
                    page.pageNo === currentPage.pageNo && 
                    JSON.stringify(page.offsets) === JSON.stringify(currentPage.offsets)
                );               
                if (!duplicatePage) {
                    acc.push(currentPage);
                }
                return acc;
            }, []);
            
            consolidated.set(key, {
                category: source.category,
                documentUUID: source.documentUUID,
                pages: uniquePages
            });
        }
    });
    
    return Array.from(consolidated.values());
  }

  const generateGuidelineCompliance = async () => {
    try {
      const { data } = await priorAuthService.generateGuidelineCompliance(
        documentUUID,
        diagnosisCode,
        procedureCode
      );
      if (hardCodedPBMDocuments.includes(fileName)) {
        setGuidelineComplianceData(guidelineCompliance[fileName]);
        setClinicalGuidelineSources(ClinicalGuidelineSources[fileName]);
      } else {
        setGuidelineComplianceData(data?.data?.adjudication_summary || data.data.message);
      dispatch(setGuidelinesSummary(data?.data?.adjudication_summary))
      dispatch(setSummaryId(data?.data?.summaryId))
      setClinicalGuidelineSources(data.data.guideline_sources || []);
      }
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    }
  };

  useEffect(() => {
    generateGuidelineCompliance();
  }, []);

  const handleSourceClick = async (
    source: ISources,
    MarkdownKey?: boolean,
    pageNumber?: number,
    chunkUUID?: string
  ) => {
    setFileUrl("");
    setChatSource(source);
    setIsCompliantDoc(MarkdownKey!);
    dispatch(setGlobalCurrentPage(pageNumber || 1));
    dispatch(setCurrentChunkUUID(chunkUUID || ""));
    try {
      const { data } = await uploadFileFolderService.getSignedURLForFullSource(source.documentUUID);
      setFileUrl(data.data.signedUrl);
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    }
  };

  const generateNewPatientSummary = async (checkboxText: string) => {
    try {
      setIsSummaryLoading(true);
      const { data } = await patientSummaryService.generatePatientSummary(
        documentUUID,
        diagnosisCode,
        procedureCode,
        checkboxText.toUpperCase().replace(/\s/g, "_")
      );
      setNewPatientSummaryData(data.data.summary || data.data.message);
      setNewPatientSummarySources(data.data.sources || data.data.message);
      setClaimsHistory(data.data?.claims_history);
    } catch (err: any) {
      console.log(err.response.data.message);
    } finally {
      setIsSummaryLoading(false);
    }
  };

  const handleCheckboxChange = (checked: boolean, checkboxText: string) => {
    setClaimsHistory("");
    const newSelectedCheckboxes = checked ? [checkboxText] : [];
    setSelectedCheckboxes(newSelectedCheckboxes);
    if (checked) {
      generateNewPatientSummary(checkboxText);
    }

    if (checkboxesRef.current) {
      const claimHistoryElement = checkboxesRef.current.querySelector(
        "#guidelines-checkboxes-group"
      );
      if (claimHistoryElement) {
        claimHistoryElement.scrollIntoView({ behavior: "smooth" });
      }
    }

    setIsCheckboxChecked(checked);
    setIsComplianceCollapsed(true);
    setShowExpandCollapse(true);
  };

  const handleRerunCompliance = async () => {
    try {
      if (isCheckboxChecked) {
        const { data } = await priorAuthService.generateGuidelineCompliance(
          documentUUID,
          diagnosisCode,
          procedureCode,
          selectedCheckboxes[0].toUpperCase().replace(/\s/g, "_")
        );
        setNewComplianceData(data.data.adjudication_summary || data.data.message);
        setShowNewCompliance(true);
        setIsFaxSummaryCollapsed(true);
      }
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    }
  };

  useEffect(() => {
    if (newComplianceData?.length != 0 && rerunRef.current) {
      const rerunElement = rerunRef.current.querySelector("#rerun-compliance-reference");
      if (rerunElement) {
        rerunElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [newComplianceData]);

  const handleGenerateAuthorization = () => {
    enableNextTab(selectedCheckboxes);
    dispatch(setSummaryType("Recommendation Summary"));
  };

  const getSourceNumberMap = (sourcesNew: ISources[]) => {
    const map: {
      [key: string]: { source: ISources; sourceNumber: number; pageNo: number; chunkUUID: string };
    } = {};
    sourcesNew?.forEach((source, index) => {
      source?.pages?.forEach((page) => {
        map[page.chunkUUID] = {
          source,
          sourceNumber: index + 1,
          pageNo: page.pageNo,
          chunkUUID: page.chunkUUID,
        };
      });
    });
    return map;
  };
  
  const renderAnswerNew = (patientSummaryNew: string, source: ISources[]): ReactNode => {

    const renderCitation = (currentLine: any) => {
  
      if( typeof(currentLine) != "string" ) {
        currentLine = currentLine.map((phrase: any) => phrase?.props?.children || phrase).join('')
      }
      const sourceNumberMap = getSourceNumberMap(source);
    
      const parts = currentLine?.split(UUID_REGEX);
      const chunkUUIDs = currentLine.match(UUID_REGEX) || [];
    
      const processedParts = parts.map((part:any, index:any) => {
        const uuid = chunkUUIDs[index];
        const citation = uuid && sourceNumberMap[uuid]
          ? `${sourceNumberMap[uuid].sourceNumber}`
          : '';
    
        return (
          <React.Fragment key={index}>
            <span>{part}</span>
            {citation && (
              <span
                className="citation-number"
                onClick={() => handleSourceClick(
                  sourceNumberMap[uuid].source, 
                  false,
                  sourceNumberMap[uuid].pageNo,
                  sourceNumberMap[uuid].chunkUUID
                )}
              >
                {citation}
              </span>
            )}
          </React.Fragment>
        );
      });
    
      return <>{processedParts}</>;
    };

    const components = {
      li(props: any) {
        
        const { children, className, node, ...rest } = props;
        return <li>{renderCitation(children)}</li>;
      },
      p(props: any) {
        const { children, className, node, ...rest } = props;
        return <p>{renderCitation(children)}</p>;
      },
    };
    return <Markdown className='citation-markdown' components={components} >{patientSummaryNew}</Markdown>;
  };
  
  const citationValue=consolidateGuidelineSources(clinicalGuidelineSources)


 const renderAnswer = useCallback(
  (guidelinesSummary: string, source: ISources[], Markdownkey?: boolean): JSX.Element => {
    const sourceNumberMap = getSourceNumberMap(source);
    const uuidRegex = /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/g;
    const parts =   guidelinesSummary.split(uuidRegex).map((part) => part?.replace(/[,[\].]| {2}/g, " ").trim())

    const chunkUUIDs = guidelinesSummary.match(uuidRegex) || [];

    const categoryPageMap = new Map<string, Set<number>>();

    const getSourceInfo = (sourceInfo: any): { 
      citationNumber: number, 
      matchingSource: ISources | null,
      shouldShowCitation: boolean  
    } => {
     
      const categoryIndex = citationValue.findIndex(
        citation => citation.category === sourceInfo.source.category
      );
   
      const matchingSource =citationValue.length !==1 ?  (categoryIndex !== -1 
        ? source.find(s => s.category === citationValue[categoryIndex].category) || null
        : null) : sourceInfo.source;

      let shouldShowCitation = false;
      if (matchingSource) {
        const category = sourceInfo.source.category;
        if (!categoryPageMap.has(category)) {
          categoryPageMap.set(category, new Set());
        }
        const pageSet = categoryPageMap.get(category)!;
        if (!pageSet.has(sourceInfo.pageNo)) {
          pageSet.add(sourceInfo.pageNo);
          shouldShowCitation = true;
        }
      }

      return {
        citationNumber: categoryIndex !== -1 ? categoryIndex + 1 : 0,
        matchingSource,
        shouldShowCitation
      };
    };

    const processedData = parts.map((part, index) => {
      const uuid = hardCodedPBMDocuments.includes(fileName) ? (source.length >1 &&source[0]?.pages[1]?.pageNo==2?"434": "123") : chunkUUIDs[index];
      if (uuid && sourceNumberMap[uuid]) {
        const sourceInfo = sourceNumberMap[uuid];
        const { citationNumber, matchingSource, shouldShowCitation } = getSourceInfo(sourceInfo);

        if (!matchingSource) {
          return <span key={index}>{part}</span>;
        }
        return (
          <>
            {Markdownkey ? (
              <span key={index}>
                <Markdown>{part}</Markdown>
                {shouldShowCitation && (
                  <span
                    className="citation-number"
                    onClick={() => handleSourceClick(
                      matchingSource,
                      false,
                      sourceInfo.pageNo,
                      sourceInfo.chunkUUID
                    )}
                  >
                    [{citationNumber}]
                  </span>
                )}
              </span>
            ) : (
              <span key={index}>
                <span>{part}</span>
                {shouldShowCitation && (
                  <span
                    className="citation-number"
                    onClick={() => handleSourceClick(
                      matchingSource,
                      true,
                      sourceInfo.pageNo,
                      sourceInfo.chunkUUID
                    )}
                  >
                    [{citationNumber}]
                  </span>
                )}
              </span>
            )}
          </>
        );
      }
      return <span key={index}>{part}</span>;
    });

    return <div>{processedData}</div>;
  },
  [citationValue, clinicalGuidelineSources, guidelineComplianceData]
);

  const renderGuidelinesCompilance = (): ReactNode => {
    return (
      <div className="pr m-b">
        <div ref={checkboxesRef} className="flex jcsb guidelines-guidelines">
          <div>GUIDELINE</div>
          {guidelineComplianceData.length > 0 && (
            <div id="guidelines-checkboxes-group">{`COMPLIANCE ${
              guidelineComplianceData.filter((item) => item.compliance_status).length
            }/${guidelineComplianceData.length}`}</div>
          )}
        </div>
        {!isComplianceCollapsed &&
          guidelineComplianceData.length > 0 &&
          guidelineComplianceData.map((item, index) => (
            <div key={index} className="flex jcsb guidelines-compliance">
              {clinicalGuidelineSources.length == 0 ||
              clinicalGuidelineSources?.length == undefined ? (
                <div style={{ width: "80%" }}>{item.guideline_description}</div>
              ) : (
                <div style={{ width: "80%" }}>
                  <div className="value">
                    {renderAnswer(item.guideline_description, clinicalGuidelineSources)}
                  </div>
                </div>
              )}
              <div style={{ margin: "15px 30px" }}>
                {item.compliance_status ? <SuccessIcon /> : <FailureIcon />}
              </div>
            </div>
          ))}
        {showExpandCollapse &&
          (isComplianceCollapsed ? (
            <div className="guidelines-downicon">
              <DownCircleOutlined
                onClick={() => {
                  setIsComplianceCollapsed(!isComplianceCollapsed);
                }}
              />
            </div>
          ) : (
            <div className="guidelines-upicon">
              <UpCircleOutlined
                onClick={() => {
                  setIsComplianceCollapsed(!isComplianceCollapsed);
                }}
              />
            </div>
          ))}
      </div>
    );
  };

  const renderClinicalGuidelineSources = (): ReactNode => {
    return (
      <div className="source-container">
        <h5>Sources: </h5>
        <ul className="source-list flex gp-10">
          {clinicalGuidelineSources.length && fileName === "Enrolee Info.pdf" 
            ? clinicalGuidelineSources.map((source, i) => (
                <li
                  className="cursor-pointer"
                  key={i}
                  onClick={() => handleSourceClick(source, true)}
                >
                  <a>{source.category}[{i + 1}]</a>
                </li>
              ))
            : consolidateGuidelineSources(clinicalGuidelineSources)?.map(
                (source: any, i) => (
                  <li
                    className="cursor-pointer"
                    key={i}
                    onClick={() => handleSourceClick(source, true)}
                  >
                    <a>{source.category}[{i + 1}]</a>
                  </li>
                )
              )}
        </ul>
      </div>
    );
  };
   
  const renderCheckboxesAndComplianceData = (): ReactNode => {
    return (
      <div>
        <div className="guidelines-checkboxes">
          <div className="p-15">{CHECKBOX_DATA}</div>
          <Checkbox
            onChange={(e) =>
              handleCheckboxChange(e.target.checked, GUIDELINES_CHECKBOXES.CALL_CENTER_LOGS)
            }
            disabled={true}
          >
            Call Center Logs
          </Checkbox>
          <Checkbox
            onChange={(e) =>
              handleCheckboxChange(e.target.checked, GUIDELINES_CHECKBOXES.CLAIMS_HISTORY)
            }
          >
            Claims History
          </Checkbox>
          <Checkbox
            onChange={(e) =>
              handleCheckboxChange(e.target.checked, GUIDELINES_CHECKBOXES.AUTH_HISTORY)
            }
            disabled={true}
          >
            Auth History
          </Checkbox>
        </div>
        {!!selectedCheckboxes.length && (
          <>
            <div style={{ marginBottom: "10px" }}>
              {selectedCheckboxes.map((checkboxText, index) => (
                <div key={index}>
                  <div className="guidelines-callFlags">
                    <div className="fw-700" key={index}>
                      {checkboxText.toUpperCase()}
                    </div>
                    {isSummaryLoading ? (
                      <Loading className="loading-icon" />
                    ) : (
                      <div className="react-typed">
                        <ReactTyped
                          strings={
                            claimsHistory
                              ? [claimsHistory.replace(/&/g, "&amp;")]
                              : ["Not generated yet"]
                          }
                          typeSpeed={1}
                          showCursor={false}
                        />
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div
              className="patientData-patientSummary guidelines-callFlags"
              style={{ height: isFaxSummaryCollapsed ? "6vh" : "auto" }}
            >
              <div className="fw-700">UPDATED PATIENT HISTORY</div> {/* Previously Fax Summary */}
              {!isFaxSummaryCollapsed &&
                (isSummaryLoading ? (
                  <Loading className="loading-icon" />
                ) : clinicalGuidelineSources.length == 0 ||
                  clinicalGuidelineSources?.length == undefined ? (
                  <div>{newPatientSummaryData}</div>
                ) : (
                  renderAnswerNew(newPatientSummaryData, newPatientSummarySources)
                ))}
              {showNewCompliance &&
                (isFaxSummaryCollapsed ? (
                  <div className="guidelines-downicon2">
                    <DownCircleOutlined
                      onClick={() => {
                        setIsFaxSummaryCollapsed(!isFaxSummaryCollapsed);
                      }}
                    />
                  </div>
                ) : (
                  <div className="guidelines-upicon2">
                    <UpCircleOutlined
                      onClick={() => {
                        setIsFaxSummaryCollapsed(!isFaxSummaryCollapsed);
                      }}
                    />
                  </div>
                ))}
            </div>
          </>
        )}
        {showNewCompliance && Array.isArray(newComplianceData) && (
          <div ref={rerunRef}>
            <div className="flex jcsb guidelines-guidelines">
              <div id="rerun-compliance-reference">GUIDELINE</div>
              <div>{`COMPLIANCE ${
                newComplianceData.filter((item) => item.compliance_status).length
              }/${newComplianceData.length}`}</div>
            </div>
            {newComplianceData.map((item, index) => (
              <div key={index} className="flex jcsb guidelines-compliance">
                {/* <div style={{ width: "80%" }}>{item.guideline_description}</div> */}
                {clinicalGuidelineSources.length == 0 ||
                clinicalGuidelineSources?.length == undefined ||
                hardCodedPBMDocuments.includes(fileName) ? (
                  <div style={{ width: "80%" }}>{item.guideline_description}</div>
                ) : (
                  <div style={{ width: "80%" }}>
                    <div className="value">
                      {renderAnswer(item.guideline_description, clinicalGuidelineSources)}
                    </div>
                  </div>
                )}
                <div style={{ margin: "15px 30px" }}>
                  {item.compliance_status ? <SuccessIcon /> : <FailureIcon />}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const renderButtonActions = (): ReactNode => {
    return (
      <div className="flex gp">
        {/* <Button
          className="outline"
          // onClick={() =>
          //   handleCopyToClipboard(
          //     JSON.stringify(guidelineComplianceData),
          //     "Guideline complaince data"
          //   )
          // }
        >
          Copy to Clipboard
        </Button> */}
        <Button
          className="fill"
          onClick={handleRerunCompliance}
          disabled={!isCheckboxChecked || isSummaryLoading}
        >
          Rerun Compliance
        </Button>
        <Button className="fill" onClick={handleGenerateAuthorization} disabled={isSummaryLoading}>
          Generate Recommendation
        </Button>
      </div>
    );
  };

  const handleThumbsClick = (type: "up" | "down") => {
    dispatch(setFeedbackType(type));
    dispatch(openModal(MODAL_TYPE.FEEDBACK));
  };

  return (
    <div className="guidelines-wrapper">
      <div className="guidelines-text-container">
        <h3>Guideline Compliance</h3>
        <div className="flex jcsb guidelines-codes">
          {projectDetails?.guidelines && <div>Guidelines : {projectDetails?.guidelines}</div>}
          {diagnosisCode?.diagnosisCode && (
            <div>{`Diagnosis Code - ${diagnosisCode?.diagnosisCode} ${diagnosisCode?.diagnosisDescription}`}</div>
          )}
          {procedureCode?.procedureCode && (
            <div>{`Procedure Code - ${procedureCode?.procedureCode} ${procedureCode?.procedureDescription}`}</div>
          )}
        </div>
        {renderGuidelinesCompilance()}
        {renderClinicalGuidelineSources()}
        {projectDetails.projectName !== "Pharmacy Auths" && renderCheckboxesAndComplianceData()}
        {!!Object.keys(chatSource).length && (
          <SourcePopup
            chatSource={chatSource}
            setChatSource={setChatSource}
            fileUrl={fileUrl}
            guideline={projectDetails.guidelines}
            isCompliantDoc={isCompliantDoc}
            fileName={fileName}
          />
        )}
      </div>
      <div className="actions-container flex jcsb">
        <div className="patientData-footer flex">
          {/* <p>Was this helpful to you?</p>
          <LikeOutlined className="cursor-pointer flex" />{" "}
        <DislikeOutlined className="cursor-pointer flex" /> */}
          <AskAuto documentUuid={documentUUID} projectId={projectId} />
          <LikeOutlined
            className="cursor-pointer flex ask-auto-button"
            onClick={() => handleThumbsClick("up")}
          />
          <DislikeOutlined
            className="cursor-pointer flex ask-auto-button"
            onClick={() => handleThumbsClick("down")}
          />
        </div>
        {renderButtonActions()}
      </div>
    </div>
  );
};

export default GuidelineCompliance;
