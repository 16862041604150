import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { SourcePopup } from "../modals/source-popup/SourcePopup";
import { ISources } from "../guideline-compliance";
import { DiagnosisProps, ProcedureProps } from ".";
import { useDispatch, useSelector } from "react-redux";
import { handleCopyToClipboard, handleDocxDownload, handlePdfDownload, openNotificationWithIcon } from "../../utils";
import { patientSummaryService, uploadFileFolderService } from "../../api";
import { FailureIcon, SuccessIcon } from "../../assets/icons";
import {setCurrentChunkUUID,setCurrentPage as setGlobalCurrentPage,} from "../../store/document/slice";
import { RootState,  } from "../../store";
import { Button, Dropdown, Menu } from "antd";
import AskAuto from "../../components/ask-auto/AskAuto";
import { CopyOutlined, DownloadOutlined } from "@ant-design/icons";

type Props = {
    documentName: string;
    enableNextTab: (procedureCode: ProcedureProps, diagnosisCode: DiagnosisProps) => void;
    documentUUID: string;
    projectId: number;
    setFileUrl: React.Dispatch<React.SetStateAction<string>>;
    fileUrl: string;
    procedureCode: any
    diagnosisCode: any
};

export const Summary = ({
  enableNextTab,
  projectId,
  setFileUrl,
  documentUUID,
  fileUrl,
  documentName,
  procedureCode,
  diagnosisCode
}: Props) => {

    const dispatch= useDispatch();
    const [chatSource, setChatSource] = useState({} as ISources);
    const { projectDetails } = useSelector((state: RootState) => state.document);
    const { patientSummary,sources } = useSelector((state: RootState) => state.exportSummary);
    
    const getSourceNumberMap = (sourcesNew: ISources[]) => {
        const map: {
          [key: string]: { source: ISources; sourceNumber: number; pageNo: number; chunkUUID: string };
        } = {};
        sourcesNew.forEach((source, index) => {
          source.pages.forEach((page) => {
            map[page.chunkUUID] = {
              source,
              sourceNumber: index + 1,
              pageNo: page.pageNo,
              chunkUUID: page.chunkUUID,
            };
          });
        });
        return map;
      };
    
    const handleSourceClick = async (source: ISources, pageNumber?: number, chunkUUID?: string) => {
        setFileUrl("");
        setChatSource(source);
        dispatch(setGlobalCurrentPage(pageNumber || 1));
        dispatch(setCurrentChunkUUID(chunkUUID || ""));
        try {
          const { data } = await uploadFileFolderService.getSignedURLForFullSource(source.documentUUID);
          setFileUrl(data.data.signedUrl);
        } catch (err: any) {
          openNotificationWithIcon("", err.response.data.message, "error");
        }
      };
    
    const renderAnswer = useCallback(
        (patientSummaryNew: string, source: ISources[]): any => {
          const sourceNumberMap = getSourceNumberMap(source);
          const uuidRegex = /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/g;
          const parts = patientSummaryNew.split(uuidRegex).map((part) =>part.trim().replace(/^[[\]]/, "").replace(/[[\]]$/, ""));
          const chunkUUIDs = patientSummaryNew.match(uuidRegex) || [];
          const processedData = parts.map((part, index) => {
            const uuid = chunkUUIDs[index];
            if (uuid && sourceNumberMap[uuid]) {
              const sourceInfo = sourceNumberMap[uuid];
              const sortedSourceNumbers = [sourceInfo.sourceNumber].sort((a, b) => a - b);
              return (
                <span key={index} style={{ fontSize: "16px" }}>
                  {part}
                  <span
                    className="citation-number"
                    onClick={() =>
                      handleSourceClick(source[0], sourceInfo.pageNo, sourceInfo.chunkUUID)
                    }
                  >
                    [{sortedSourceNumbers}]
                  </span>
                </span>
              );
            }
          });
          return (
            <div>{processedData}</div>
          );
        },
        [sources,patientSummary]
      );
  
    const renderSummary = (): ReactNode => {
        return (
            < >
            <h3>Summary</h3>
              <div className="display-auth-content">
              <span className="adjudicationSummary-title">Reason for denial: {' '}</span>
                {patientSummary.summary}
                <span className="adjudicationSummary-title">Case Fact: {' '}</span>
                {patientSummary.case_facts}
              </div>
            </>

        );
      };
      
    const renderObservation = (): ReactNode => {
        return (
            <>
            <h3 className="heading-detailed-complience">Questionnaire Observation</h3>
            {patientSummary.validations?.map((item: any, index: number) => {
              return (
               item.sources.length > 0 && <div key={index} className="display-auth-content" style={{borderBottom: "1px solid lightgray",marginBottom: "10px",paddingBottom: "10px"}}>
                <div className="flex">
                  <span className="adjudicationSummary-title">Question: {' '}</span>
                  {item?.question}
                </div>
                <div className="">
                <span className="adjudicationSummary-title">Observation:{' '}</span>{renderAnswer(item?.observation, sources)}
              </div>
              <div className="">
              <span className="adjudicationSummary-title">Validation Status:{' '}</span>
              {item?.validation}
            </div>
                </div>
              );
            })}
            </>
        );
      };

      const renderMedicalHistorySources = (): ReactNode => {
        return (
          <div className="source-container">
            <h5>Sources: </h5>
            <div className="source-list flex gp-10">
              {!!sources?.length &&
                sources?.map((source:any, i:any) => {
                  return (
                    <li className="cursor-pointer" key={i} onClick={() => handleSourceClick(source)}>
                      <a>{source?.category}[1]</a>
                    </li>
                  );
                })}
            </div>
          </div>
        );
      };

      const exportMenu = (
        <Menu>
          <Menu.Item
            key="pdf"
            icon={<DownloadOutlined />}
            className="export-dropdown-item"
            onClick={() => handlePdfDownload(patientSummary, "Patient Summary", documentName)}
          >
            Download as PDF
          </Menu.Item>
          <Menu.Item
            key="docx"
            icon={<DownloadOutlined />}
            className="export-dropdown-item"
            onClick={() => handleDocxDownload(patientSummary, "Patient Summary", documentName)}
          >
            Download as Docx
          </Menu.Item>
          <Menu.Item
            key="copy"
            icon={<CopyOutlined />}
            className="export-dropdown-item"
            onClick={() => handleCopyToClipboard(patientSummary, "Patient Summary")}
          >
            Copy to Clipboard
          </Menu.Item>
        </Menu>
      );

  return (
    <div className="authorization-container">
      <div className="authorization-container-content">
        <section className="display-auth">
        {renderSummary()}
        {renderObservation()}
        {renderMedicalHistorySources()}
        {!!Object.keys(sources).length && (
        <SourcePopup
            chatSource={chatSource}
            setChatSource={setChatSource}
            fileUrl={fileUrl}
            guideline={projectDetails.guidelines}
        />
        )}
        </section>
        
      </div>
          <div className="patientData-footer" >
            <div className="actions">
              <AskAuto buttonClassName="action" documentUuid={documentUUID} projectId={projectId} />
            </div>
            <div className="flex gp">
              <Dropdown overlay={exportMenu} placement="topCenter" trigger={["click"]}>
                <Button className="outline">Export Summary as</Button>
              </Dropdown>
              {/* <Button
                className="fill"
                onClick={handleGenerateCompliance}
              >
                Generate Guideline Compliance
              </Button> */}
            </div>
          </div>
    </div>
  );
};

export default Summary;

